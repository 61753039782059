import {useEffect, useState} from "react";

export default function Navbar() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "light");
  // const [theme, setTheme] = useState('forest');
  useEffect(() => {
    localStorage.setItem('theme', theme!)
    const localTheme = localStorage.getItem('theme')
    document.querySelector('html')?.setAttribute('data-theme', localTheme!)
  }, [theme]);

  const toggleTheme = (e:any) => {
    if (e.target.checked) {
      setTheme('acid');
    } else {
      setTheme('forest');
    }
  };



  return (
    <div
      className="navbar sticky top-0 z-50 bg-base-300 pb-4 text-neutral-content shadow-lg"
      id="navbar"
    >
      <div className="dropdown lg:hidden">
        <label tabIndex={0} className="btn btn-ghost btn-circle">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
        </label>
        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
          <li><a href="#home" className="btn btn-ghost btn-sm rounded-btn">
            Home
          </a></li>
          <li><a href="#portfolio" className="btn btn-ghost btn-sm rounded-btn">
            Portfolio
          </a></li>
          <li><a href="#about-me" className="btn btn-ghost btn-sm rounded-btn">
            About
          </a></li>
          <li><a href="#technologies" className="btn btn-ghost btn-sm rounded-btn">
            Technologies
          </a></li>
          <li><a
              href="mailto:diegogar577@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="btn btn-ghost btn-sm rounded-btn"
          >
            Contact
          </a></li>
        </ul>
      </div>

      <div className=" mx-2 flex-none px-2">
        <span className="hidden sm:block font-bold text-lg">Diego O. Garcia</span>
      </div>

      <div className="mx-2 flex-1 px-2">
        <div className="hidden items-stretch lg:flex">
          <a href="#home" className="btn btn-ghost btn-sm rounded-btn">
            Home
          </a>
          <a href="#portfolio" className="btn btn-ghost btn-sm rounded-btn">
            Portfolio
          </a>
          <a href="#about-me" className="btn btn-ghost btn-sm rounded-btn">
            About
          </a>
          <a href="#technologies" className="btn btn-ghost btn-sm rounded-btn">
            Technologies
          </a>
          <a
            href="mailto:diegogar577@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="btn btn-ghost btn-sm rounded-btn"
          >
            Contact
          </a>
        </div>
      </div>
      <div className="flex-none">
        <label className="swap swap-rotate">

          {/*this hidden checkbox controls the state*/}
          <input type="checkbox" onClick={toggleTheme} />

          {/* sun icon*/}
          <svg className="swap-on fill-current w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>

          {/*  moon icon*/}
          <svg className="swap-off fill-current w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>

        </label>
      </div>
    </div>
  );
}
